
.dp {
    position: relative;
    width: 280px;
    height: 280px;
    display: flex;
    /* float: right; */
    justify-content: center;
    align-items: center;    
    border-radius: 50%;
    overflow: hidden;
    /* bottom: 1px; */
    /* bottom: -50px;
    right: 150px; */
    /* right: 1px; */
    transition: 600ms;

}

.dp:hover {
    transform: scale(1.08);

}

.dp img{
    
    position: relative;
    width: 90%;
    height: 90%;
    z-index: 1;
    border-radius: 50%;
    object-fit:cover;
    object-position:-65px 0px;
    filter: brightness(0.6) contrast(1.06);

}

.dp::after{
    content: "";
    position:absolute;
    background: #0a192f;
    inset: 3px;
    border-radius: 50%;

}

.dp::before{
    content: "";
    position: absolute;
    /* background: linear-gradient(#36e7f4,#ebd43c); */
    /* background-color: #36f1f4; */
    background-color: #3c8b9d;
    width: 95%;
    height: 95%;
    animation: borderrotate 10s linear infinite ;
    /* animation-duration:normal; */
    transition: 600ms;

}

.dp:hover::before{
    background-color: rgb(16 185 129);
    animation-duration: 6s;
}

@keyframes borderrotate {
    0%{
        transform: rotate(0deg);
    }

    
    
    100%{
        transform: rotate(360deg);
    }
}


@media only screen and (max-width: 768px) {

    .dp {
        border-radius: 5%;
    }

    .dp img{
        border-radius: 5%;
        object-position:-40px 0px;
  
    }

    .dp::after{
        border-radius: 5%;
    
    }
    
    .dp::before{
        width: 85%;
        height: 140%;
    }
    
}
