@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .moveup {
        @apply transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none;
    }

    .bounce {
        @apply animate-bounce;
    }
    
    
}


@layer base {
    @font-face {
        font-family: "calibre";
        font-weight: 600;
        src: url('../fonts/Calibre/Calibre-Semibold.woff') format('woff'),url('../fonts/Calibre/Calibre-Semibold.woff2') format('woff2');
    }
    @font-face {
        font-family: "calibre";
        font-weight: 400;
        src: url('../fonts/Calibre/Calibre-Regular.woff') format('woff'),url('../fonts/Calibre/Calibre-Regular.woff2') format('woff2');
    }
 
}